import styled from 'styled-components';

export const Container = styled.section`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5rem;

  .body {
    margin: 50px 0px;
    margin-bottom: 15%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-family: var(--font-regular);
    color: black;

    .body-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      > * {
        width: 50%;
      }

      .body-title {
        font-family: var(--font-bold);
        color: var(--primary-color);
        font-size: 3.5rem;
        line-height: 3.5rem;
        word-break: break-word;
      }

      .body-content {
        text-align: justify;
        line-height: 25px;
        padding-bottom: 20px;
        p:not(:first-child) {
          padding-top: 1rem;
        }
      }
    }

    @media (max-width: 720px) {
      height: 100%;
      margin-top: 2rem;
      .body-wrapper {
        flex-direction: column;
        > * {
          width: 100%;
        }

        .body-title {
          font-size: 2.5rem;
          line-height: 2.8rem;
          padding-bottom: 20px;
        }
      }
    }
  }
`;
