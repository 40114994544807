import React from "react";
import Layout from "../components/Layout/Layout";
import { OurPeopleGrid, Video } from "components";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "../styles/ourStory.styles";
import { Wrapper } from "../components/globalElements";
import useMedia from "../hooks/useMedia";

const OurStory = () => {
  const {
    allDatoCmsTeamMember,
    datoCmsStory: { heroVideo, heroTitle, heroParagraphText, teamTitle },
  } = useStaticQuery(query);
  const { isTablet } = useMedia();
  const team = allDatoCmsTeamMember.edges.map(({ node }) => node);

  return (
    <Layout fullScreen={isTablet} title="Our Story">
      <Container>
        <Video src={heroVideo.video.mp4Url} />
        <Wrapper>
          <div className="body">
            <div className="body-wrapper">
              <h2 className="body-title">{heroTitle}</h2>
              <div
                className="body-content"
                dangerouslySetInnerHTML={{
                  __html: heroParagraphText,
                }}
              />
            </div>
          </div>
        </Wrapper>
      </Container>
      <Wrapper style={{ marginBottom: "5rem" }}>
        <OurPeopleGrid title={teamTitle} team={team} />
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allDatoCmsTeamMember {
      edges {
        node {
          name
          title
          description
          id
          image {
            gatsbyImageData
            alt
          }
        }
      }
    }
    datoCmsStory {
      heroTitle
      heroParagraphText
      heroVideo {
        video {
          streamingUrl
          thumbnailUrl
          mp4Url
        }
        title
        alt
      }
      id
      teamTitle
    }
  }
`;

export default OurStory;
